import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../../../content/produk/controller/videoprocessor/NOVASTAR VX6S/Product-Page.json";
import Datasheet from "../../../../content/produk/controller/videoprocessor/NOVASTAR VX6S/datasheet-novastar-vx6s.pdf";
import ProductImage from "../../../../images/product/controller/videoprocessor/NOVASTAR VX6S/Product-Summary-Novastar-VX6s.jpg";
const novastarvx6s = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Video Processor Novastar VX6S</h1>
                </div>
                <Row>
                    <Col>
                        <Image src={ProductImage} fluid alt={"Gambar modul"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className={"section-title"}>
                            <h2>Product Summary</h2>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                            VX6S adalah controller all in one yang
                            mengintegrasikan fungsi sending card dengan video
                            processing. Didesain dengan kemampuan pemrosesan
                            video yang kuat, vx6s mendukung 7 input video dan 6
                            output gigabit ethernet. Berbasis FPGA, VX6S
                            mendukung berbagai efek transisi seperti switching
                            dan fade yang cepat dan mulus, menyediakan kendali
                            layar yang fleksibel dan presentasi video yang luar
                            biasa. VX6S dilengkapi dengan kartu ekspansi yang
                            dapat dihubungkan dengan drive usb untuk memainkan
                            media di dalamnya dan dengan menghubungkan mouse dan
                            monitor USB playback dapat dengan dimonitor real
                            time.
                        </p>
                        <ul style={{ textAlign: "justify" }}>
                            <li>
                                Features 7 input connectors: 2 × 3G-SDI, 2 ×
                                HDMI1.3, 2 × DVI, 1 × USB.
                            </li>
                            <li>Supports 3 × layers and 1 × OSD.</li>
                            <li>
                                Supports quick and advanced screen
                                configurations.
                            </li>
                            <li>
                                Switches the PVW to PGM by pressing only the
                                TAKE button in the switcher mode.
                            </li>
                            <li>Supports PGM preview in the switcher mode.</li>
                            <li>
                                Supports adjustment of input resolutions and
                                backup of input source.
                            </li>
                            <li>
                                Supports brightness adjustment of the screen
                                loaded by the VX6s.
                            </li>
                            <li>Multiple VX6s units can be cascaded.</li>
                            <li>
                                Automatically scales the image to fit the whole
                                screen.
                            </li>
                            <li>
                                The maximum video output width is 4096 pixels.
                            </li>
                            <li>
                                A total of 16 user presets can be created and
                                saved as templates. The templates can be used
                                directly and conveniently.
                            </li>
                            <li>
                                Any HDMI or DVI input source can be used as the
                                synchronization signal to achieve vertical
                                synchronization of output.
                            </li>
                            <li>
                                Features an intuitive OLED screen and clear
                                button indicator lights in the front panel,
                                simplifying system control and operation.
                            </li>
                        </ul>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

novastarvx6s.propTypes = {
    siteTitle: PropTypes.string,
};

novastarvx6s.defaultProps = {
    siteTitle: ``,
};
export default novastarvx6s;
